import { Search } from "@mui/icons-material";
import { alpha, Box, InputAdornment, styled, TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.white, 0.5),
  "&:hover, &:focus-within": {
    backgroundColor: alpha(theme.palette.common.white, 1),
  },
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
    backgroundColor: alpha(theme.palette.common.white, 0),
  },
}));

interface IMapSearchField {
  currentQuery: string;
  onChange: (query: string) => void;
}

export const MapSearchField: React.FC<IMapSearchField> = ({
  currentQuery: query,
  onChange,
}) => {
  const [t] = useTranslation();

  return (
    <Box sx={{ position: "absolute", left: 15, top: 15 }}>
      <StyledTextField
        onChange={(e) => onChange(e.currentTarget.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        value={query}
        autoComplete="off"
        placeholder={t("general.fields.search") + "..."}
      />
    </Box>
  );
};
